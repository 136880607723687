import throttle from "lodash/throttle";
import { useEffect, useRef } from "react";

import { Layout } from "~/components/site/layout";
import { init } from "~/lib/blob";

type Props = Readonly<{
  statusCode: number;
  themed: boolean;
}>;

export function Error({ statusCode, themed }: Props): JSX.Element {
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvas.current) init(canvas.current, throttle);
  }, [canvas]);

  return (
    <Layout backgroundImage={false} themed={themed}>
      <div
        id="blob-container"
        className="flex grow justify-center items-center relative min-h-full"
      >
        <div className="flex flex-row absolute">
          <span className="text-9xl four0four z-10 text-white dark:text-black">
            {statusCode === 404 ? "4" : "5"}
          </span>
          <span className="zero-spacer block"></span>
          <span className="text-9xl four0four z-10 text-white dark:text-black">
            {statusCode === 404 ? "4" : "0"}
          </span>
        </div>

        <img
          alt="Brale Orb"
          className="absolute"
          src="/assets/site/bg-blurry-orb.webp"
          height="0"
          width="525"
        />
        <canvas
          dangerouslySetInnerHTML={{
            __html: `<!-- The blob was created by Lian Egan - https://twitter.com/liamegan -->`,
          }}
          ref={canvas}
          id="blob"
          className="z-10"
          data-blob-size="sm"
        ></canvas>
      </div>
    </Layout>
  );
}
